import React from 'react'
import './css/QuizzHeaderDisplayer.css';
import {ScoreDisplayer} from "../common/ScoreDisplayer";
import {FlopSelectorDisplayer} from "./FlopSelectorDisplayer";

export class FlopHeaderDisplayer extends React.Component<any, any> {

    render() {
        return (
            <div className={'quizzHeader'}>
                <FlopSelectorDisplayer quizzManager={this.props.quizzManager}/>
                <ScoreDisplayer quizzManager={this.props.quizzManager}/>
            </div>
        )
    }
}