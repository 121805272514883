
import React from 'react'
import './css/PlayerRangeDisplayer.css'

export class PlayerRangeDisplayer extends React.Component<any, any> {

    componentDidMount() {
        this.props.quizzManager.registerSubscriberQuestionChange((question: any) => {
            this.receiveChangeQuestionNotification(question)
        })
    }

    receiveChangeQuestionNotification(question: any) {
        this.setState({question: question})
    }

    render(){
        if(!this.state)
            return null

        if(!this.state.question)
            return null

        let range = ''
        if(this.props.playerId === 1)
            range = this.state.question.player1Range
        else
            range = this.state.question.player2Range

        if(!range)
            return null

        return (
            <div className="rangeDisplayerArea">
                {range}
            </div>
        )
    }
}