import React, {CSSProperties} from "react";
import {Button} from "react-bootstrap";

export class ValidateQuestionButtonDisplayer extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = {answerChecked: false}
    }

    componentDidMount() {
        this.props.quizzManager.registerSubscriberQuestionChange((question: any) => {
            this.receiveChangeQuestionNotification(question)
        })

        this.props.quizzManager.registerSubscriberQuestionAvailableForCheck(() => {
            this.receiveQuestionAvailableForCheckNotification()
        })
    }

    receiveChangeQuestionNotification(question: any) {
        this.setState({answerChecked: false})
    }

    receiveQuestionAvailableForCheckNotification() {
        this.setState({answerChecked: true})
    }

    private clickValidateAnswer() {
        this.props.quizzManager.questionDone()
        this.setState({answerChecked: false})
    }

    render(){
        if (!this.state)
            return null

        return (
            <this.display
                quizzManager={this.props.quizzManager}
                onClick={() => this.clickValidateAnswer()}
                answerChecked={this.state.answerChecked}
            />
        )
    }

    display(props: any) {
        const displayAnswerButton = props.answerChecked

        const myButtonTextStyle: CSSProperties = {
            fontSize: 'large',
            color: 'white',
        }

        return(
            <Button variant="default" className='myButtonStyle' disabled={!displayAnswerButton}
                    onClick={props.onClick}><div style={myButtonTextStyle}>Validate answer</div>
            </Button>
        )
    }
}