import React, {CSSProperties} from 'react'
import './css/QuestionDisplayer.css'
import {PlayerInfoDisplayer} from "./PlayerInfoDisplayer"
import {StaticMatrixDisplayer} from "../matrix/StaticMatrixDisplayer"
import {BoardDisplayer} from "./BoardDisplayer"
import {QuestionAnswerDisplayer} from "./QuestionAnswerDisplayer"
import {PlayerRangeDisplayer} from "./PlayerRangeDisplayer";
import {HoleCardsDisplayer} from "./HoleCardsDisplayer";

export class QuestionDisplayer extends React.Component<any, any> {

    constructor(props: any) {
        super(props)
        this.state = {questionNotAnswered: true}
    }

    componentDidMount() {
        this.props.quizzManager.registerQuizzStoppedChange(() => {
            this.receiveQuizzStoppedNotification()
        })

        this.props.quizzManager.registerSubscriberQuestionDone(() => {
            this.receiveChangeQuestionDone()
        })

        this.props.quizzManager.registerSubscriberQuestionChange((question: any) => {
            this.receiveChangeQuestionNotification(question)
        })
    }

    private receiveQuizzStoppedNotification() {
        this.setState({question: null, questionNotAnswered: true})
    }

    private receiveChangeQuestionDone() {
        this.setState({questionNotAnswered: false})
    }

    private receiveChangeQuestionNotification(question: any) {
        this.setState({question: question, questionNotAnswered: true})
    }

    render() {
        if (!this.state)
            return null

        if (!this.state.question)
            return null

        console.log(`FlopQuestionDisplayer- this.state.question: ${JSON.stringify(this.state.question)}`)

        return (
            <this.display
                player1Hand={this.state.question.player1Hand}
                player2Hand={this.state.question.player2Hand}
                quizzManager={this.props.quizzManager}
            />
        )
    }

    display(props: any) {
        const cardsMatrixArea: CSSProperties = {
            marginTop: '10px'
        }

        return (
            <div className='questionDisplayer'>
                <div className='middlePart'>
                    <BoardDisplayer quizzManager={props.quizzManager}/>
                    <QuestionAnswerDisplayer quizzManager={props.quizzManager}/>
                </div>
                <div className='playerBlockArea'>
                    <PlayerInfoDisplayer playerId={1} quizzManager={props.quizzManager}/>
                    <div style={cardsMatrixArea}>
                        {!props.player1Hand && <StaticMatrixDisplayer playerId={1} quizzManager={props.quizzManager}/>}
                        {props.player1Hand &&
                        <HoleCardsDisplayer card={props.player1Hand} loadCardsAndThumbsFromFile={props.quizzManager._loadCardsAndThumbsFromFile}/>}
                    </div>
                    <PlayerRangeDisplayer playerId={1} quizzManager={props.quizzManager}/>
                </div>
                <div className='playerBlockArea'>
                    <PlayerInfoDisplayer playerId={2} quizzManager={props.quizzManager}/>
                    <div style={cardsMatrixArea}>
                        {!props.player2Hand && <StaticMatrixDisplayer playerId={2} quizzManager={props.quizzManager}/>}
                        {props.player2Hand &&
                        <HoleCardsDisplayer card={props.player2Hand} loadCardsAndThumbsFromFile={props.quizzManager._loadCardsAndThumbsFromFile}/>}
                    </div>
                    <PlayerRangeDisplayer playerId={2} quizzManager={props.quizzManager}/>
                </div>
            </div>
        )
    }
}