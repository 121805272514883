export type QuizzItemInfos = {
    id: number,         // 1
    type: string,       // 'range' or 'various'
    street: string,     // Preflop
    move: string,     // Open, Call, 3bet
    title: string,      // Open Raise
    serverURL: string,  // openraise
    rangeUTG: boolean[][][],   // AA-22 for each position
    rangeUTGvsPositionLabel: string[],   // ['UTG', 'HJ']
    rangeHJ: boolean[][][],
    rangeHJvsPositionLabel: string[],
    rangeCO: boolean[][][],
    rangeCOvsPositionLabel: string[],
    rangeBT: boolean[][][],
    rangeBTvsPositionLabel: string[],
    rangeSB: boolean[][][],
    rangeSBvsPositionLabel: string[],
    rangeBB: boolean[][][],
    rangeBBvsPositionLabel: string[],
    questions: any      // Question list grabbed from the store
    nbQuestionsAvailable: number,
    selected: boolean,  // Ranges item is selected by the user
    nbQuestionsPreselected: number
}

export class RegisterAllQuizzItemInfos {

    static getQuizzItemInfos() {
        let quizzInfosList: Array<QuizzItemInfos> = []
        for (let nbQuizz = 0; nbQuizz < 8; nbQuizz++) {
            let quizzItemInfo: QuizzItemInfos = {
                id: nbQuizz,
                type: '',
                street: '',
                move: '',
                title: '',
                serverURL: '',
                rangeUTG: [],
                rangeUTGvsPositionLabel: [],
                rangeHJ: [],
                rangeHJvsPositionLabel: [],
                rangeCO: [],
                rangeCOvsPositionLabel: [],
                rangeBT: [],
                rangeBTvsPositionLabel: [],
                rangeSB: [],
                rangeSBvsPositionLabel: [],
                rangeBB: [],
                rangeBBvsPositionLabel: [],
                questions: [],
                nbQuestionsAvailable: 0,
                selected: false,
                nbQuestionsPreselected: 0
            }

            switch (quizzItemInfo.id) {
                case 0:
                    quizzItemInfo.type = 'range'
                    quizzItemInfo.street = 'preflop'
                    quizzItemInfo.move = 'Open raise'
                    quizzItemInfo.title = 'Open raise'
                    quizzItemInfo.serverURL = 'openraise'
                    break
                case 1:
                    quizzItemInfo.type = 'range'
                    quizzItemInfo.street = 'preflop'
                    quizzItemInfo.move = 'Call raise'
                    quizzItemInfo.title = 'Call raise'
                    quizzItemInfo.serverURL = 'callraise'
                    break
                case 2:
                    quizzItemInfo.type = 'range'
                    quizzItemInfo.street = 'preflop'
                    quizzItemInfo.move = '3bet'
                    quizzItemInfo.title = '3bet'
                    quizzItemInfo.serverURL = '3bet'
                    break
                case 3:
                    quizzItemInfo.type = 'various'
                    quizzItemInfo.street = 'preflop'
                    quizzItemInfo.title = 'Hand vs Hand'
                    quizzItemInfo.serverURL = 'preflopequityhandvshand'
                    break
                case 4:
                    quizzItemInfo.type = 'various'
                    quizzItemInfo.street = 'preflop'
                    quizzItemInfo.title = 'Hand vs Range'
                    quizzItemInfo.serverURL = 'preflopequityhandvsrange'
                    break
                case 5:
                    quizzItemInfo.type = 'various'
                    quizzItemInfo.street = 'flop'
                    quizzItemInfo.title = 'Range advantage R vs R'
                    quizzItemInfo.serverURL = 'flopequityrangevsrange'
                    break
                case 6:
                    quizzItemInfo.type = 'various'
                    quizzItemInfo.street = 'flop'
                    quizzItemInfo.title = 'Flop types'
                    quizzItemInfo.serverURL = 'floptypes'
                    break
                case 7:
                    quizzItemInfo.type = 'various'
                    quizzItemInfo.street = 'flop'
                    quizzItemInfo.title = 'Flop hit'
                    quizzItemInfo.serverURL = 'flophithandvsflops'
                    break
                default:
                    console.log('Error on loading Quizz Items')
                    break
            }

            quizzInfosList.push(quizzItemInfo)
        }

        return quizzInfosList
    }
}