import React, {CSSProperties} from 'react'
import './css/FlopDisplayer.css'

export class BoardDisplayer extends React.Component<any, any> {

    componentDidMount() {
        this.props.quizzManager.registerSubscriberQuestionChange((question: any) => {
            this.receiveChangeQuestionNotification(question)
        })
    }

    receiveChangeQuestionNotification(question: any) {
        this.setState({question: question})
    }

    render() {
        if (!this.state)
            return null

        if (!this.state.question)
            return null

        const flop = this.state.question.flop
        //console.log(`Flop: ${flop}`)
        if (!flop)
            return null

        const flopCard1 = flop[0] + flop[1] + '.png'
        const flopCard2 = flop[2] + flop[3] + '.png'
        const flopCard3 = flop[4] + flop[5] + '.png'

        const cardStyle: CSSProperties = {
            padding: '0px 2px 5px 2px'
        }

        return (
                <div>
                    <fieldset className="myFlopDisplayerFieldset">
                        <legend className="w-auto myFlopDisplayerLegend">Board</legend>
                        <img src={this.props.quizzManager._loadCardsAndThumbsFromFile._cardImages[flopCard1].default} style={cardStyle}
                             alt={flopCard1} width={'70px'}
                             height={'auto'}/>
                        <img src={this.props.quizzManager._loadCardsAndThumbsFromFile._cardImages[flopCard2].default} style={cardStyle}
                             alt={flopCard2} width={'70px'}
                             height={'auto'}/>
                        <img src={this.props.quizzManager._loadCardsAndThumbsFromFile._cardImages[flopCard3].default} style={cardStyle}
                             alt={flopCard3} width={'70px'}
                             height={'auto'}/>
                    </fieldset>
                </div>
        )
    }
}