import React from "react";
import './css/MultipleRangesMatrixDisplayer.css'
import {StaticRangesMatrixDisplayerWithTitle} from "./StaticRangesMatrixDisplayerWithTitle";

export class MultipleRangesMatrixDisplayer extends React.Component<any, any> {
    private _nbMatrixInserted = 0

    private buildMultipleMatrix() : JSX.Element{
        let htmlResults = []
        for(let i=0; i<2 && this._nbMatrixInserted < this.props.range.length; i++){
            htmlResults.push(this.buildOneLine())
        }

        return (
            <div className='MyMatrixDisplay'>
                {htmlResults}
            </div>
        )
    }

    private buildOneLine(): JSX.Element{
        let htmlResults = []
        let nbMaxMatrixPerLine = 3
        if(this.props.range.length < 5)
            nbMaxMatrixPerLine = 2

        for(let i=0; i<nbMaxMatrixPerLine && this._nbMatrixInserted < this.props.range.length; i++){
            htmlResults.push(this.buildOneMatrix())
            this._nbMatrixInserted++
        }

        return (
            <div className='MyMatrixLine'>
                {htmlResults}
            </div>
        )
    }

    private buildOneMatrix() : JSX.Element{
        let labelMatrix: string[] = []

        //console.log(`buildOneMatrix- this.props.rangesVSLabelPosition: ${JSON.stringify(this.props.rangesVSLabelPosition)}`)

        this.props.rangesVSLabelPosition.forEach((position: [any]) => {    // [""] ou ["UTG', "HJ"]
            if(position.length > 0)
                labelMatrix.push(this.props.quizzTitle + ' - ' + this.props.positionLabel + ' vs ' + position)
            else
                labelMatrix.push(this.props.quizzTitle)
        })

        return (<StaticRangesMatrixDisplayerWithTitle title={labelMatrix[this._nbMatrixInserted]} range={this.props.range[this._nbMatrixInserted]}/>)
    }

    render(){
        //console.log(`MultipleRangesMatrixDisplayer- rangesVSLabelPosition: ${JSON.stringify(this.props.rangesVSLabelPosition)}`)
        //console.log(`MultipleRangesMatrixDisplayer- range.length: ${this.props.range.length}`)

        this._nbMatrixInserted = 0

        return (this.buildMultipleMatrix())
    }
}