import React from 'react'
import {StaticSquareDisplayer} from "./StaticSquareDisplayer";

export class StaticMatrixDisplayer extends React.Component<any, any> {

    componentDidMount() {
        this.props.quizzManager.registerSubscriberQuestionChange((question: any) => {
            this.receiveQuestionChange(question)
        })
    }

    receiveQuestionChange(question: any) {
        this.setState({question: question})
    }

    renderMatrix() {
        let matrix = []

        for (let raw = 0; raw < 13; raw++) {
            if (this.props.playerId === 1)
                // @ts-ignore
                matrix.push(this.renderMatrixRaw(raw, this.state.question.Player1RangeBooleanArray))
            else
                // @ts-ignore
                matrix.push(this.renderMatrixRaw(raw, this.state.question.Player2RangeBooleanArray))
        }

        return (
            <div key={`${this.props.playerId}`}>
                {matrix}
            </div>
        )
    }

    renderMatrixRaw(raw: number, range: [][]) {
        let squareArray = []
        for (let index = 0; index < 13; index++) {
            squareArray.push(this.renderMatrixSquare(raw, index, range))
        }

        return (
            <div key={`${this.props.playerId}_${raw}`}>
                {squareArray}
            </div>
        )
    }

    renderMatrixSquare(raw: number, index: number, range: [][]): JSX.Element {
        return (
            <StaticSquareDisplayer
                key={`${this.props.playerId}_${raw}_${index}`}
                range={range}
                row={raw}
                index={index}
            />
        )
    }

    render() {
        //console.log(`Render static Matrix Player${this.props.playerId}  ${this.state.quizz.Player1Range}`)

        if (!this.state)
            return null

        if(!this.state.question)
            return null

        return (this.renderMatrix())
    }
}