import React from "react";
import {StaticRangesMatrixDisplayer} from "./StaticRangesMatrixDisplayer";
import './css/StaticRangesMatrixDisplayerWithTitle.css'

export class StaticRangesMatrixDisplayerWithTitle extends React.Component<any, any> {
    render() {
        //console.log(`StaticRangesMatrixDisplayerWithTitle- this.props.title: ${this.props.title}`)
        return (
            <div className={'MyMatrixWithTitleDisplay'}>
                {this.props.title}
                <StaticRangesMatrixDisplayer range={this.props.range}/>
            </div>
        )
    }
}