import React from 'react'
import './css/CaptureAnswerDisplayer.scss'

export class CaptureAnswerDisplayer extends React.Component<any, any> {
    private _question!: any

    constructor(props: any) {
        super(props)
        this.state = {disableRadio: false}

        this.props.quizzManager.registerSubscriberQuestionChange((question: any) => {
            this.receiveChangeQuestionNotification(question)
        })

        this.props.quizzManager.registerSubscriberQuestionDone(() => {
            this.receiveChangeQuestionDone()
        })
    }

    receiveChangeQuestionNotification(question: any) {
        this._question = question
        this.setState({disableRadio: false, checkedRadio: null})
    }

    receiveChangeQuestionDone() {
        this.setState({disableRadio: true})
    }

    onChangeAnswer = (event: any) => {
        //console.log(`Event Player ${event.target.value}`)
        this.setState({checkedRadio: event.target.value})
        this.props.quizzManager.questionAvailableForCheck(event.target.id)
    }

    render() {
        let answerProposition = [this._question.proposition1, this._question.proposition2, this._question.proposition3, this._question.proposition4, this._question.proposition5]
        let radioLabelStyle = []
        let radioControlStyle = []
        let radioCheckStyle = []

        if (!this.state)
            return null

        for (let i = 0; i < 5; i++) {
            radioLabelStyle[i] = 'radio__label'
            radioControlStyle[i] = 'radio__control'
            radioCheckStyle[i] = (this.state.checkedRadio === answerProposition[i])

            if (this.state.disableRadio)
                if ((parseInt(this._question.answer) - 1) !== i) {
                    if(this.state.checkedRadio === answerProposition[i]){
                        radioLabelStyle[i] = 'radio__label radioLabelBadSelectedAnswer'
                        radioControlStyle[i] = 'radio__control radioControlBadSelectedAnswer'
                    }else{
                        radioLabelStyle[i] = 'radio__label radioLabelBadAnswer'
                        radioControlStyle[i] = 'radio__control radioControlBadAnswer'
                    }

                } else {
                    radioLabelStyle[i] = 'radio__label radioLabelGoodAnswer'
                    radioControlStyle[i] = 'radio__control radioControlGoodAnswer'
                    radioCheckStyle[i] = true
                }
        }

        return (
            <this.display
                answerProposition={answerProposition}
                radioLabelStyle={radioLabelStyle}
                radioControlStyle={radioControlStyle}
                radioCheckStyle={radioCheckStyle}
                disableRadio={this.state.disableRadio}
                onChangePlayer={this.onChangeAnswer}
                checkedRadio={this.state.checkedRadio}
            />
        )
    }


    display(props: any) {
        return (
            <div>
                <div>
                    <label className="radio radio-gradient">
                              <span className="radio__input">
                                  <input
                                      type="radio"
                                      id={'1'}
                                      value={props.answerProposition[0]}
                                      disabled={props.disableRadio}
                                      checked={props.radioCheckStyle[0]}
                                      onChange={props.onChangePlayer}
                                  />
                                <span className={props.radioControlStyle[0]}></span>
                              </span>
                        <span className={props.radioLabelStyle[0]}>{props.answerProposition[0]}</span>
                    </label>
                </div>

                <div>
                    <label className="radio radio-gradient">
                              <span className="radio__input">
                                  <input
                                      type="radio"
                                      id={'2'}
                                      value={props.answerProposition[1]}
                                      disabled={props.disableRadio}
                                      checked={props.radioCheckStyle[1]}
                                      onChange={props.onChangePlayer}
                                  />
                                <span className={props.radioControlStyle[1]}></span>
                              </span>
                        <span className={props.radioLabelStyle[1]}>{props.answerProposition[1]}</span>
                    </label>
                </div>

                {props.answerProposition[2] && <div>
                    <label className="radio radio-gradient">
                              <span className="radio__input">
                                  <input
                                      type="radio"
                                      id={'3'}
                                      value={props.answerProposition[2]}
                                      disabled={props.disableRadio}
                                      checked={props.radioCheckStyle[2]}
                                      onChange={props.onChangePlayer}
                                  />
                                <span className={props.radioControlStyle[2]}></span>
                              </span>
                        <span className={props.radioLabelStyle[2]}>{props.answerProposition[2]}</span>
                    </label>
                </div>}

                {props.answerProposition[3] && <div>
                    <label className="radio radio-gradient">
                              <span className="radio__input">
                                  <input
                                      type="radio"
                                      id={'4'}
                                      value={props.answerProposition[3]}
                                      disabled={props.disableRadio}
                                      checked={props.radioCheckStyle[3]}
                                      onChange={props.onChangePlayer}
                                  />
                                <span className={props.radioControlStyle[3]}></span>
                              </span>
                        <span className={props.radioLabelStyle[3]}>{props.answerProposition[3]}</span>
                    </label>
                </div>}

                {props.answerProposition[4] && <div className='lastRadio'>
                    <label className="radio radio-gradient">
                              <span className="radio__input">
                                  <input
                                      type="radio"
                                      id={'5'}
                                      value={props.answerProposition[4]}
                                      disabled={props.disableRadio}
                                      checked={props.radioCheckStyle[4]}
                                      onChange={props.onChangePlayer}
                                  />
                                <span className={props.radioControlStyle[4]}></span>
                              </span>
                        <span className={props.radioLabelStyle[4]}>{props.answerProposition[4]}</span>
                    </label>
                </div>}
            </div>
        )
    }
}


//<input type="radio" name="name" id="nameB" value="no-one" onChange={this.onEquityChangedPlayer1}/>
//<label htmlFor="nameB">No-One</label>

// https://tympanus.net/codrops/2012/09/13/button-switches-with-checkboxes-and-css3-fanciness/
// https://moderncss.dev/pure-css-custom-styled-radio-buttons/