import React from 'react';
import './App.css';
import {Switch, Route} from "react-router-dom";
import {Home} from "./Home";
import {Navigation} from "./navigation/Navigation";
import {FlopDisplayer} from "./flop/FlopDisplayer";
import {UserProfileDisplayer} from "./userprofile/UserProfileDisplayer";
import {UserProfile} from "./userprofile/UserProfile";
import {Suggestions} from "./suggestions/Suggestions";
import {PreflopDisplayer} from "./preflop/PreflopDisplayer";

class App extends React.Component<any, any> {
    _userProfile: UserProfile

    constructor(props: any) {
        super(props)

        this._userProfile = new UserProfile()
    }

    render() {
        return (
            <div>
                <Navigation />
                <Switch>
                    <Route path="/" component={Home} exact/>
                    <Route path="/preflopquiz" component={() => <PreflopDisplayer userProfile={this._userProfile} />}/>
                    <Route path="/flopquiz" component={() => <FlopDisplayer userProfile={this._userProfile} />}/>
                    <Route path="/userprofile" component={() => <UserProfileDisplayer userProfile={this._userProfile} />}/>
                    <Route path="/suggestions" component={Suggestions}/>
                </Switch>
            </div>
        )
    }
}

export default App;

// https://medium.com/swlh/responsive-navbar-using-react-bootstrap-5e0e0bd33bd6
// https://medium.com/@yehiasaleh/internationalization-localization-using-react-intl-typescript-1e7cfccd34d7

