import React from "react";
import './css/RangesModalDisplayer.css'
import {Tab, Tabs} from "react-bootstrap";
import {MultipleRangesMatrixDisplayer} from "../matrix/MultipleRangesMatrixDisplayer";

export class RangesModalDisplayer extends React.Component<any, any> {
    private _positionLabel = ['UTG', 'HJ', 'CO', 'BT', 'SB', 'BB']

    render() {
        const showHideClassName = this.props.show ? "modal display-block" : "modal display-none";

        if (showHideClassName === 'modal display-none')
            return null

        if (!this.props.range)
            return null

        let rangesPresence: boolean[] = []
        this.props.range.forEach((position: [any]) => {
            rangesPresence.push(position.length > 0)
        })

        const defaultActiveKey = this.getDefaultActiveKey(rangesPresence)

        return (
            <div className={showHideClassName}>
                <section className="modal-main">
                    <Tabs defaultActiveKey={defaultActiveKey} className="categories m-3">
                        {rangesPresence[0] && <Tab eventKey={this._positionLabel[0]} title={this._positionLabel[0]}>
                            <MultipleRangesMatrixDisplayer positionLabel={this._positionLabel[0]} rangesVSLabelPosition={this.props.rangesVSLabelPosition[0]} range={this.props.range[0]} quizzTitle={this.props.quizzTitle}/>
                        </Tab>}
                        {rangesPresence[1] && <Tab eventKey={this._positionLabel[1]} title={this._positionLabel[1]}>
                            <MultipleRangesMatrixDisplayer positionLabel={this._positionLabel[1]} rangesVSLabelPosition={this.props.rangesVSLabelPosition[1]} range={this.props.range[1]} quizzTitle={this.props.quizzTitle}/>
                        </Tab>}
                        {rangesPresence[2] && <Tab eventKey={this._positionLabel[2]} title={this._positionLabel[2]}>
                            <MultipleRangesMatrixDisplayer positionLabel={this._positionLabel[2]} rangesVSLabelPosition={this.props.rangesVSLabelPosition[2]} range={this.props.range[2]} quizzTitle={this.props.quizzTitle}/>
                        </Tab>}
                        {rangesPresence[3] && <Tab eventKey={this._positionLabel[3]} title={this._positionLabel[3]}>
                            <MultipleRangesMatrixDisplayer positionLabel={this._positionLabel[3]} rangesVSLabelPosition={this.props.rangesVSLabelPosition[3]} range={this.props.range[3]} quizzTitle={this.props.quizzTitle}/>
                        </Tab>}
                        {rangesPresence[4] && <Tab eventKey={this._positionLabel[4]} title={this._positionLabel[4]}>
                            <MultipleRangesMatrixDisplayer positionLabel={this._positionLabel[4]} rangesVSLabelPosition={this.props.rangesVSLabelPosition[4]} range={this.props.range[4]} quizzTitle={this.props.quizzTitle}/>
                        </Tab>}
                        {rangesPresence[5] && <Tab eventKey={this._positionLabel[5]} title={this._positionLabel[5]}>
                            <MultipleRangesMatrixDisplayer positionLabel={this._positionLabel[5]} rangesVSLabelPosition={this.props.rangesVSLabelPosition[5]} range={this.props.range[5]} quizzTitle={this.props.quizzTitle}/>
                        </Tab>}
                    </Tabs>
                    <div className='MyModalButton'>
                        <button type="button" onClick={this.props.handleClose}>
                            Close
                        </button>
                    </div>
                </section>
            </div>
        )
    }

    private getDefaultActiveKey(rangesPresence: boolean[]) {
        return this._positionLabel[rangesPresence.indexOf(true)]
    }
}