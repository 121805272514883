import React, {CSSProperties} from 'react'
import './css/QuizzSelectorDisplayer.css'
import {Button} from "react-bootstrap";
import {QuizzItemInfos} from "../common/RegisterAllQuizzItemInfos";
import {QuizzManager} from "../common/QuizzManager";

export class FlopSelectorDisplayer extends React.Component<any, any> {
    private _quizzManager!: QuizzManager

    constructor(props: any) {
        super(props)
        this._quizzManager = this.props.quizzManager
        this.state = {startStopButtonEnable: false, canSelectQuizz: true}
    }

    componentDidMount() {
        if(this._quizzManager) {
            this._quizzManager.registerQuizzStoppedChange(() => {
                this.receiveQuizzStoppedNotification()
            })
        }
    }

    private receiveQuizzStoppedNotification() {
        this.setState({startStopButtonEnable: false, canSelectQuizz: true})
    }

    private clickStartStopQuizz() {
        if (this.state.canSelectQuizz) {
            this.setState({canSelectQuizz: false})
            this._quizzManager.startQuizz()
        } else {
            this._quizzManager.stopQuizz()
            this.setState({canSelectQuizz: true})
        }
    }

    onClickQuizzChoice(index: number) {
        //console.log(`Click quizz choice ${index}`)
        const quizzItemInfosIds = [5, 6, 7]
        this._quizzManager.checkOnQuizzItemSelector(index, quizzItemInfosIds)

        let startStopButtonEnable = false
        this._quizzManager._questionsDataStore._quizzItemInfos.forEach((quizzInfoItem: QuizzItemInfos)=>{
            startStopButtonEnable = startStopButtonEnable || quizzInfoItem.selected
        })
        this.setState({startStopButtonEnable: startStopButtonEnable})
    }

    render() {
        if(!this._quizzManager)
            return null

        let displayTextOnButton = 'Start Quiz'
        if (!this.state.canSelectQuizz)
            displayTextOnButton = 'End Quiz'

        let quizzTitles: Array<string> = []
        let quizzStyle: Array<string> = []
        let onClickQuizzChoice: Array<()=>void> = []
        let nbQuestionsPerQuizzItem: Array<number> = []
        this._quizzManager._questionsDataStore._quizzItemInfos.forEach((quizzItemInfos: QuizzItemInfos )=> {
            if(quizzItemInfos.street === 'flop') {
                quizzTitles.push(quizzItemInfos.title)
                quizzStyle.push(quizzItemInfos.selected ? 'questionTypeSelected' : 'questionTypeUnselected')
                onClickQuizzChoice.push(() => this.onClickQuizzChoice(quizzItemInfos.id))
                nbQuestionsPerQuizzItem.push(quizzItemInfos.nbQuestionsPreselected)
            }
        })

        //console.log(`QuizzSelector: quizzTitles: ${quizzTitles}`)

        return (
            <this.display
                onClickStartQuizz={() => this.clickStartStopQuizz()}
                canSelectQuizz={this.state.canSelectQuizz}
                startStopButtonEnable={this.state.startStopButtonEnable}
                displayTextOnButton={displayTextOnButton}
                quizzTitles={quizzTitles}
                quizzStyle={quizzStyle}
                onClickQuizzChoice={onClickQuizzChoice}
                nbQuestionsPerQuizzItem={nbQuestionsPerQuizzItem}
            />
        )
    }

    display(props: any) {
        const sectionStyle: CSSProperties = {margin: '10px 0 0 0'}
        //console.log(`startButtonEnable ${props.startStopButtonEnable}  canSelectQuizz ${props.canSelectQuizz}`)

        const myButtonTextStyle: CSSProperties = {
            fontSize: 'larger',
            color: 'white',
        }

        return (
            <div>
                <fieldset className="myQuizzSelectorFieldset">
                    <legend className="w-auto myLegendQuizzSelector">Choose your quizzes</legend>
                    <div className='questionSection' style={sectionStyle}>
                        <button className={props.quizzStyle[0]} onClick={props.onClickQuizzChoice[0]}
                                disabled={!props.canSelectQuizz}><b>{props.quizzTitles[0]}</b> {' (' + props.nbQuestionsPerQuizzItem[0] + ' questions)'}</button>
                        <button className={props.quizzStyle[1]} onClick={props.onClickQuizzChoice[1]}
                                disabled={!props.canSelectQuizz}><b>{props.quizzTitles[1]}</b> {' (' + props.nbQuestionsPerQuizzItem[1] + ' questions)'}</button>
                        <button className={props.quizzStyle[2]} onClick={props.onClickQuizzChoice[2]}
                                disabled={!props.canSelectQuizz}><b>{props.quizzTitles[2]}</b> {' (' + props.nbQuestionsPerQuizzItem[2] + ' questions)'}</button>
                    </div>

                </fieldset>
                <div className='buttonStartQuizz'>
                    <Button variant="default" className='myButtonStyle' disabled={!props.startStopButtonEnable}
                            onClick={props.onClickStartQuizz}><div style={myButtonTextStyle}>{props.displayTextOnButton}</div></Button>
                </div>
            </div>
        )
    }
}