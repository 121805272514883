import React from "react";
import './css/PreflopDisplayer.css'
import {PreflopHeaderDisplayer} from "./PreflopHeaderDisplayer";
import {QuestionDescriptionDisplayer} from "../common/QuestionDescriptionDisplayer";
import {QuizzManager} from "../common/QuizzManager";
import {NavigationInQuizzPage} from "../navigation/NavigationInQuizzPage";
import {QuestionDisplayer} from "../common/QuestionDisplayer";

export class PreflopDisplayer extends React.Component<any, any> {
    _quizzManager: QuizzManager

    constructor(props: any) {
        super(props);
        this._quizzManager = new QuizzManager(this.props.userProfile)
        this.state = {displayHelpers: true}
    }

    componentDidMount() {
        this._quizzManager.registerItemQuizzSelectorChecked(() => {
            this.registerQuizzRangesSelectorCheckedNotification()
        })
    }

    registerQuizzRangesSelectorCheckedNotification() {
        const displayErgoHelpers = this._quizzManager._maxQuestionNumberForOngoingQuizz === 0
        this.setState({displayHelpers: displayErgoHelpers})
    }

    render() {
        return (
            <div className='ranges'>
                <div>
                    <PreflopHeaderDisplayer quizzManager={this._quizzManager} />
                </div>
                {!this.state.displayHelpers ?
                    <div>
                        <QuestionDescriptionDisplayer quizzManager={this._quizzManager}/>
                        <QuestionDisplayer quizzManager={this._quizzManager}/>
                    </div>
                    : <NavigationInQuizzPage />
                }
            </div>
        )
    }
}