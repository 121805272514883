import React, {CSSProperties} from 'react'
import './Home.css'
import './index.css'
import {faChartLine, faEnvelope, faPlay, faBuilding} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {Button, Nav} from "react-bootstrap";

export class Home extends React.Component<any, any> {
    render() {

        const textStyle: CSSProperties = {
            padding: '0 0 0 15px',
        }

        const myButtonTextStyle: CSSProperties = {
            fontSize: 'x-large',
            color: 'white',
        }

        const MyIconTextLineStyle: CSSProperties = {
            display: 'flex',
            paddingTop: '22px',
        }

        return (
            <div className='home'>
                <div className='mainPresentationDescription'>
                    <div className='topRightPresentationDescription'>
                        <p className='enjoy'>Improve your poker skills like preflop equity knowledge, hand reading and more</p>
                        <div className="myButtonAreaContainer">
                            <div>
                                <Nav.Link as={Link} to='/preflopquiz'>
                                    <Button variant="default" className='myButtonStyle' style={myButtonTextStyle}>
                                        Preflop quiz
                                    </Button>
                                </Nav.Link>
                            </div>
                            <div >
                                <Nav.Link as={Link} to='/flopquiz'>
                                    <Button variant="default" className='myButtonStyle' style={myButtonTextStyle}>
                                        Flop quiz
                                    </Button>
                                </Nav.Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'menuDescription'}>
                        <div className='menuWithImageDescription'>
                            <div>
                                <img src={process.env.PUBLIC_URL + 'pokerclassrooms-home.jpg'} alt='Poker Player' width={'400'}                                   height={'auto'}/>
                            </div>
                            <div className='rightPresentationDescription'>
                                <div style={MyIconTextLineStyle}>
                                    <FontAwesomeIcon icon={faBuilding} color="grey" size="lg"/>
                                    <div style={textStyle}>Improve your preflop ranges knowledge</div>
                                </div>
                                <div style={MyIconTextLineStyle}>
                                    <FontAwesomeIcon icon={faPlay} color="grey" size="lg"/>
                                    <div style={textStyle}>Select one or more quizzes to answer</div>
                                </div>
                                <div style={MyIconTextLineStyle}>
                                    <FontAwesomeIcon icon={faChartLine} color="grey" size="lg"/>
                                    <div style={textStyle}>See your progress and your PCR
                                        Level</div>
                                </div>
                                <div style={MyIconTextLineStyle}>
                                    <FontAwesomeIcon icon={faEnvelope} color="grey" size="lg"/>
                                    <div style={textStyle}>Suggest enhancements or report bugs</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}