import React, {CSSProperties} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Nav} from "react-bootstrap";

export class NavigationToProgress extends React.Component {
    render() {
        const textStyle: CSSProperties = {
            fontSize: 'large',
            padding: '5px 0 0 20px'
        }

        const iconStyle: CSSProperties = {
            margin: '0 0 0 0'
        }

        return (
            <div>
                <Nav.Link as={Link} to='/userprofile'>
                    <FontAwesomeIcon icon={faChartLine} color="tomato" size="3x" style={iconStyle}/>
                    <div style={textStyle}>Check your progression</div>
                </Nav.Link>
            </div>
        )
    }
}