import React from 'react'
import './css/PCRChart.css';
import {ResponsiveLine} from '@nivo/line'

export class PCRChart extends React.Component<any, any> {
    render() {
        return (
            <div className='container-nivochart'>
                <ResponsiveLine
                    data={this.props.userProfile._dataForCharts}
                    margin={{top: 50, right: 110, bottom: 50, left: 60}}
                    xScale={{type: 'point'}}
                    yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false}}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    colors={{ scheme: 'category10' }}
                    axisBottom={{
                        legend: 'Nb questions answered',
                        legendPosition: 'middle',
                        legendOffset: 40
                    }}
                    axisLeft={{
                        legend: 'PCR Level',
                        legendPosition: 'middle',
                        legendOffset: -50
                    }}
                />
            </div>
        )
    }
}

// https://medium.com/swlh/beautiful-and-responsive-line-charts-using-nivo-react-and-material-ui-18d2cbd2953b
// npm install @nivo/core
// npm install @nivo/line