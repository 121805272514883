import React, {CSSProperties} from 'react'
import './css/UserProfile.css';
import {PCRChart} from "./PCRChart";
import avatar from '../resources/Avatar.png'
import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Nav} from "react-bootstrap";
import {Link} from "react-router-dom";

export class UserProfileDisplayer extends React.Component<any, any> {

    render() {
        const textStyle: CSSProperties = {
            fontStyle: "italic",
            color: "#000000",
            padding: '0 0 0 15px',
            fontSize: 'larger',
            display: 'flex',
            alignItems: 'center'
        }

        const missingChartStyle: CSSProperties = {
            display: 'flex',
            flexDirection: 'row'
        }

        const avatarQuestionAnsweredStyle: CSSProperties = {
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid grey',
            borderRadius: '1.5rem',
            padding: '15px'
        }

        const myButtonTextStyle: CSSProperties = {
            fontSize: 'large',
            color: 'white',
        }

        return (
            <div className='userprofile'>
                <div style={avatarQuestionAnsweredStyle}>
                    <img src={avatar} width="100" height="100" alt=""/>
                    <div>
                        <div style={textStyle}>Questions answered: {this.props.userProfile._nbQuestionsAnswered}</div>
                        <p></p>
                        {this.props.userProfile._PCRLevelLabel &&
                        <div style={textStyle}>PCR Level: {this.props.userProfile._PCRLevelLabel} ({this.props.userProfile._PCRLevel})</div>}
                    </div>
                </div>
                <div>
                    {this.props.userProfile._PCRLevelLabel ?
                        <PCRChart userProfile={this.props.userProfile}/>
                        : <div style={missingChartStyle}><FontAwesomeIcon icon={faChartLine} color="tomato" size="8x"/>
                            <div style={textStyle}>Chart will appear when Quiz questions will be answered</div>
                        </div>}
                </div>
                <div className="myButtonsContainer">
                    <div>
                        <Nav.Link as={Link} to='/preflopquiz'>
                            <Button variant="default" className='myButtonStyle' style={myButtonTextStyle}>
                                Train on preflop quiz
                            </Button>
                        </Nav.Link>
                    </div>
                    <div >
                        <Nav.Link as={Link} to='/flopquiz'>
                            <Button variant="default" className='myButtonStyle' style={myButtonTextStyle}>
                                Train on flop quiz
                            </Button>
                        </Nav.Link>
                    </div>
                </div>
            </div>
        )
    }
}