export class LoadCardsAndThumbsFromFile {
    _cardImages: { [key: string]: any } = {}
    _thumbImages: { [key: string]: any } = {}

    constructor() {
        this._cardImages = this.importAllCardImages(require.context('../resources/cards', false, /\.png/))
        this._thumbImages = this.importAllCardImages(require.context('../resources/thumbs', false, /\.jpg/))
    }

    importAllCardImages(requirement: __WebpackModuleApi.RequireContext) {
        let images: { [key: string]: any } = {}
        requirement.keys().forEach((item, index) => {
            images[item.replace('./', '')] = requirement(item);
        })

        //console.log(`Images ${JSON.stringify(images)}`)

        return images
    }
}