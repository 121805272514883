import React, {CSSProperties} from 'react'
import './Suggestions.css'
import {Button, Nav} from "react-bootstrap";

export class Suggestions extends React.Component<any, any> {

    render() {
        const textStyle: CSSProperties = {
            padding: '10px 0 0 20px'
        }

        const myButtonTextStyle: CSSProperties = {
            fontSize: 'x-large',
            color: 'white',
        }

        const myButtonSpacing: CSSProperties = {
            padding: '30px 0 0 0'
        }

        return (
            <div className='suggestions'>
                <div className='myBlocks'>
                    <div className='suggestionsExplanation'>Current suggestions/bugs in priority order</div>
                    <div style={textStyle}>- More quiz please</div>
                    <div style={textStyle}>- Overall ranking displayed on Profile page</div>
                    <div style={textStyle}>- French version</div>
                </div>
                <div style={myButtonSpacing}>
                    <Nav.Link href='mailto: reloaded.poker@gmail.com'>
                        <Button variant="default" className='myButtonStyle'>
                            <div style={myButtonTextStyle}>Submit a bug or a new feature</div>
                        </Button>
                    </Nav.Link>
                </div>
            </div>
        )
    }
}