import React, {CSSProperties} from "react";
import {Button} from "react-bootstrap";

export class NextQuestionButtonDisplayer extends React.Component<any, any> {

    clickNextQuestion() {
        this.props.quizzManager.changeQuestion()
    }

    render() {
        return (
            <this.display
                onClick={() => this.clickNextQuestion()}
            />
        )
    }

    display(props: any) {

        const myButtonTextStyle: CSSProperties = {
            fontSize: 'large',
            color: 'white'
        }

        return (
            <Button variant="default" className='myButtonStyle' onClick={props.onClick}>
                <div style={myButtonTextStyle}>Next Question</div>
            </Button>
        )
    }
}