import prange from "prange";

export type RangesQuestionInfos = {
    question_id: string,
    thumb_id: string,
    questionTitle: string,      // Open Raise
    player2Name: string,
    player2Hand: string,
    position: string,
    description: string,
    proposition1: string,
    proposition2: string,
    answer: string,
    pointAnswer1: string,
    pointAnswer2: string
}

export class RangesQuestionEnricher {
    private _handLabels = [
        ['AA', 'AKs', 'AQs', 'AJs', 'ATs', 'A9s', 'A8s', 'A7s', 'A6s', 'A5s', 'A4s', 'A3s', 'A2s'],
        ['AKo', 'KK', 'KQs', 'KJs', 'KTs', 'K9s', 'K8s', 'K7s', 'K6s', 'K5s', 'K4s', 'K3s', 'K2s'],
        ['AQo', 'KQo', 'QQ', 'QJs', 'QTs', 'Q9s', 'Q8s', 'Q7s', 'Q6s', 'Q5s', 'Q4s', 'Q3s', 'Q2s'],
        ['AJo', 'KJo', 'QJo', 'JJ', 'JTs', 'J9s', 'J8s', 'J7s', 'J6s', 'J5s', 'J4s', 'J3s', 'J2s'],
        ['ATo', 'KTo', 'QTo', 'JTo', 'TT', 'T9s', 'T8s', 'T7s', 'T6s', 'T5s', 'T4s', 'T3s', 'T2s'],
        ['A9o', 'K9o', 'Q9o', 'J9o', 'T9o', '99', '98s', '97s', '96s', '95s', '94s', '93s', '92s'],
        ['A8o', 'K8o', 'Q8o', 'J8o', 'T8o', '98o', '88', '87s', '86s', '85s', '84s', '83s', '82s'],
        ['A7o', 'K7o', 'Q7o', 'J7o', 'T7o', '97o', '87o', '77', '76s', '75s', '74s', '73s', '72s'],
        ['A6o', 'K6o', 'Q6o', 'J6o', 'T6o', '96o', '86o', '76o', '66', '65s', '64s', '63s', '62s'],
        ['A5o', 'K5o', 'Q5o', 'J5o', 'T5o', '95o', '85o', '75o', '65o', '55', '54s', '53s', '52s'],
        ['A4o', 'K4o', 'Q4o', 'J4o', 'T4o', '94o', '84o', '74o', '64o', '54o', '44', '43s', '42s'],
        ['A3o', 'K3o', 'Q3o', 'J3o', 'T3o', '93o', '83o', '73o', '63o', '53o', '43o', '33', '32s'],
        ['A2o', 'K2o', 'Q2o', 'J2o', 'T2o', '92o', '82o', '72o', '62o', '52o', '42o', '32o', '22']
    ]

    enrichQuestions(brutPositionsList: any) {
        let enrichQuestionsList: any[] = []
        brutPositionsList.forEach((lineInCSV: any) => {    // (UTG, HJ,CO, BT, SB, BB)
            if (lineInCSV.player2Position) {
                let computedQuestions = this.computedQuestions(lineInCSV)
                enrichQuestionsList.push(computedQuestions)
            }
        })
        return enrichQuestionsList
    }

    private computedQuestions(lineInCSV: any): any[] {
        let computedQuestions: any[] = []
        let twoHoleCards = ''
        let answer = '1'
        let pointAnswer1 = '3'
        let pointAnswer2 = '0'
        let rangeQuestionInfo: RangesQuestionInfos = {
            question_id: '',
            thumb_id: '',
            questionTitle: '',
            player2Name: '',
            player2Hand: '',
            position: '',
            description: '',
            proposition1: '',
            proposition2: '',
            answer: '',
            pointAnswer1: '',
            pointAnswer2: '',
        }

        const range = this.convertTextRangeToBooleanArray(lineInCSV.player2Range)
        const excludedRange = this.convertTextRangeToBooleanArray(lineInCSV.player2ExcludedRange)

        for (let index = 0; index < 13; index++)
            for (let raw = 0; raw < 13; raw++) {
                if(!excludedRange[index][raw]) {
                    twoHoleCards = RangesQuestionEnricher.getTwoHoleCards(this._handLabels[index][raw])
                    if (range[index][raw]) {
                        answer = '1'
                        pointAnswer1 = '3'
                        pointAnswer2 = '0'
                    } else {
                        answer = '2'
                        pointAnswer1 = '0'
                        pointAnswer2 = '3'
                    }

                    rangeQuestionInfo = {
                        question_id: lineInCSV.question_id,
                        thumb_id: lineInCSV.thumb_id,
                        questionTitle: lineInCSV.questionTitle,      // Open Raise
                        player2Name: lineInCSV.player2Name,
                        player2Hand: twoHoleCards.substring(0, 2) + twoHoleCards.substring(2, 4),
                        position: lineInCSV.player2Position,
                        description: lineInCSV.description,
                        proposition1: lineInCSV.proposition1,
                        proposition2: lineInCSV.proposition2,
                        answer: answer,
                        pointAnswer1: pointAnswer1,
                        pointAnswer2: pointAnswer2,
                    }

                    //console.log(`RangeQuestionInfo: ${JSON.stringify(rangeQuestionInfo)}`)
                    computedQuestions.push(rangeQuestionInfo)
                }
            }

        return computedQuestions
    }

    private static getTwoHoleCards(cards: string) {
        let colored = ['s', 'c', 'd', 'h']
        let computedCards :string

        if (cards.length === 2 ||
            (cards.length === 3 && cards[2] === 'o')) {
            const firstColor = Math.floor(Math.random() * colored.length)
            computedCards = cards[0] + colored[firstColor]

            colored.splice(firstColor, 1)

            const secondColor = Math.floor(Math.random() * colored.length)
            computedCards += cards[1] + colored[secondColor]
        } else {
            const firstColor = Math.floor(Math.random() * colored.length)
            computedCards = cards[0] + colored[firstColor] + cards[1] + colored[firstColor]
        }

        return computedCards
    }

    public convertTextRangeToBooleanArray(range: string): boolean[][] {
        let rangePlayer: boolean[][] = new Array(13).fill(false).map(() => new Array(13).fill(false))

        if (range) {
            const expandedRange = prange(range)

            expandedRange.forEach((hand: string) => {
                for (let index = 0; index < 13; index++)
                    for (let raw = 0; raw < 13; raw++)
                        if (hand === this._handLabels[index][raw]) {
                            rangePlayer[index][raw] = true
                        }
            })
        }

        return rangePlayer
    }
}