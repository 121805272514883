import React from 'react'
import './css/EquityAnswerDisplayer.css'

export class EquityAnswerDisplayer extends React.Component<any, any> {
    private _question!: any

    constructor(props: any) {
        super(props)

        this.props.quizzManager.registerSubscriberQuestionChange((question: any) => {
            this.receiveChangeQuestionNotification(question)
        })
    }

    receiveChangeQuestionNotification(question: any) {
        this._question = question
    }

    render() {
        if(!this._question.player1Name || !this._question.player1Equity)
            return null

        return (
            <div className='answerDisplayer'>
                <div>{`${this._question.player1Name} equity: ${this._question.player1Equity}%`}</div>
                <div>{`${this._question.player2Name} equity: ${this._question.player2Equity}%`}</div>
            </div>
        )
    }
}