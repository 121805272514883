import React, {CSSProperties} from "react";
import {Button, Form} from "react-bootstrap";
import './css/PreflopSelectorDisplayer.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {RangesModalDisplayer} from "../common/RangesModalDisplayer";
import {QuizzManager} from "../common/QuizzManager";
import {QuizzItemInfos} from "../common/RegisterAllQuizzItemInfos";

export class PreflopSelectorDisplayer extends React.Component<any, any> {
    private _quizzManager!: QuizzManager
    private _showModal = [false, false]

    constructor(props: any) {
        super(props)
        this._quizzManager = this.props.quizzManager
        this.state = {startStopButtonEnable: false, canSelectQuizz: true, showModal: this._showModal}
    }

    componentDidMount() {
        if (this._quizzManager)
            this._quizzManager.registerQuizzStoppedChange(() => {
                this.receiveQuizzStoppedNotification()
            })
    }

    private receiveQuizzStoppedNotification() {
        this.setState({startStopButtonEnable: false, canSelectQuizz: true})
    }

    onShowModal(index: number) {
        this._showModal[index] = true
        this.setState({showModal: this._showModal});
    }

    onHideModal(index: number) {
        this._showModal[index] = false
        this.setState({showModal: this._showModal});
    }

    private clickStartStopQuizz() {
        if (this.state.canSelectQuizz) {
            this.setState({canSelectQuizz: false})
            this.props.quizzManager.startQuizz()
        } else {
            this.props.quizzManager.stopQuizz()
            this.setState({canSelectQuizz: true})
        }
    }

    onClickQuizzChoice(index: number) {
        //console.log(`RangesSelector onClickQuizzChoice index: ${index}`)
        const quizzItemInfosIds = [0, 1, 2, 3, 4]
        this.props.quizzManager.checkOnQuizzItemSelector(index, quizzItemInfosIds)

        this.setRangesInfoItemSelection()
        const startStopButtonEnable = this.setStateStartButton()
        this.setState({startStopButtonEnable: startStopButtonEnable})
    }

    onClickPositionFilter(position: number) {
        //console.log(`RangesSelector onClickPositionFilter position: ${position}`)
        const quizzItemInfosIds = [0, 1, 2, 3, 4]
        this._quizzManager.checkOnClickPositionFilter(position, quizzItemInfosIds)

        this.setRangesInfoItemSelection()
        const startStopButtonEnable = this.setStateStartButton()
        this.setState({startStopButtonEnable: startStopButtonEnable})
    }

    setStateStartButton() {
        let startStopButtonEnable = false
        this.props.quizzManager._questionsDataStore._quizzItemInfos.forEach((rangesInfoItem: QuizzItemInfos) => {
            startStopButtonEnable = startStopButtonEnable || (rangesInfoItem.selected && rangesInfoItem.nbQuestionsPreselected > 0)
        })

        return startStopButtonEnable
    }

    setRangesInfoItemSelection() {
        this._quizzManager._questionsDataStore._quizzItemInfos.forEach((rangesInfoItem: QuizzItemInfos) => {
            if (rangesInfoItem.nbQuestionsPreselected === 0)
                rangesInfoItem.selected = false
        })
    }

    render() {
        if (!this._quizzManager)
            return null

        let displayTextOnButton = 'Start Quiz'
        if (!this.state.canSelectQuizz)
            displayTextOnButton = 'End Quiz'

        //console.log(`PreflopSelectorDisplayer: render`)

        let ranges: boolean[][][][][] = []
        let rangesVSLabelPosition: string[][][] = []
        let quizzTitles: Array<string> = []
        let rangesStyle: Array<string> = []
        let onClickRangesChoice: Array<() => void> = []
        let onClickPositionFilter: Array<() => void> = []
        let nbQuestionsPerQuizzItem: Array<number> = []
        let onShowModal: Array<(index: number) => void> = []
        let onHideModal: Array<(index: number) => void> = []
        this._quizzManager._questionsDataStore._quizzItemInfos.forEach((quizzItemInfos: QuizzItemInfos) => {
            const rangeByPosition: boolean[][][][] = [quizzItemInfos.rangeUTG, quizzItemInfos.rangeHJ, quizzItemInfos.rangeCO, quizzItemInfos.rangeBT, quizzItemInfos.rangeSB, quizzItemInfos.rangeBB]
            ranges.push(rangeByPosition)
            const rangesvsPositionLabel: string[][] = [quizzItemInfos.rangeUTGvsPositionLabel, quizzItemInfos.rangeHJvsPositionLabel, quizzItemInfos.rangeCOvsPositionLabel, quizzItemInfos.rangeBTvsPositionLabel, quizzItemInfos.rangeSBvsPositionLabel, quizzItemInfos.rangeBBvsPositionLabel]
            rangesVSLabelPosition.push(rangesvsPositionLabel)
            quizzTitles.push(quizzItemInfos.title)
            rangesStyle.push(quizzItemInfos.selected ? 'questionTypeSelected' : 'questionTypeUnselected')
            onClickRangesChoice.push(() => this.onClickQuizzChoice(quizzItemInfos.id))
            nbQuestionsPerQuizzItem.push(quizzItemInfos.nbQuestionsPreselected)
            onShowModal.push(() => this.onShowModal(quizzItemInfos.id))
            onHideModal.push(() => this.onHideModal(quizzItemInfos.id))
        })

        for (let position = 0; position < 6; position++)
            onClickPositionFilter.push(() => this.onClickPositionFilter(position))

        if (!ranges[0])
            return null

        return (
            <this.display
                onClickStartStopQuizz={() => this.clickStartStopQuizz()}
                canSelectQuizz={this.state.canSelectQuizz}
                startStopButtonEnable={this.state.startStopButtonEnable}
                displayTextOnButton={displayTextOnButton}
                ranges={ranges}
                rangesVSLabelPosition={rangesVSLabelPosition}
                quizzTitles={quizzTitles}
                rangesStyle={rangesStyle}
                onClickRangesChoice={onClickRangesChoice}
                nbQuestionsPerQuizzItem={nbQuestionsPerQuizzItem}
                positionFilters={this.props.quizzManager._questionsDataStore._positionFilter}
                onClickPositionFilter={onClickPositionFilter}
                showModalState={this.state.showModal}
                onShowModal={onShowModal}
                onHideModal={onHideModal}
            />
        )
    }

    display(props: any) {
        const myButtonTextStyle: CSSProperties = {
            fontSize: 'larger',
            color: 'white',
        }

        //console.log(`Ranges selector display- canSelectQuizz: ${props.canSelectQuizz}`)

        return (
            <div>
                <fieldset className="myRangesSelectorFieldset">
                    <legend className="w-auto myRangesLegendSelector">Choose your quizzes</legend>
                    <div className='MyQuestionSelectorSection'>
                        <b>Ranges</b>
                        <div className='rangesSection'>
                            <RangesModalDisplayer show={props.showModalState[0]} handleClose={props.onHideModal[0]}
                                                  range={props.ranges[0]} quizzTitle={props.quizzTitles[0]}
                                                  rangesVSLabelPosition={props.rangesVSLabelPosition[0]}>
                                <p>Modal</p>
                            </RangesModalDisplayer>
                            <button className='myRangeDisplayButton' onClick={props.onShowModal[0]}
                                    disabled={!props.canSelectQuizz}><FontAwesomeIcon
                                icon={faEye} color="#000000" size="sm"/></button>
                            <button className={props.rangesStyle[0]} onClick={props.onClickRangesChoice[0]}
                                    disabled={!props.canSelectQuizz}>
                                <b>{props.quizzTitles[0]}</b> {' (' + props.nbQuestionsPerQuizzItem[0] + ' questions)'}
                            </button>
                        </div>
                        <div className='rangesSection'>
                            <RangesModalDisplayer show={props.showModalState[1]} handleClose={props.onHideModal[1]}
                                                  range={props.ranges[1]} quizzTitle={props.quizzTitles[1]}
                                                  rangesVSLabelPosition={props.rangesVSLabelPosition[1]}>
                                <p>Modal</p>
                            </RangesModalDisplayer>
                            <button className='myRangeDisplayButton' onClick={props.onShowModal[1]}
                                    disabled={!props.canSelectQuizz}><FontAwesomeIcon
                                icon={faEye} color="#000000" size="sm"/></button>
                            <button className={props.rangesStyle[1]} onClick={props.onClickRangesChoice[1]}
                                    disabled={!props.canSelectQuizz}>
                                <b>{props.quizzTitles[1]}</b> {' (' + props.nbQuestionsPerQuizzItem[1] + ' questions)'}
                            </button>
                        </div>
                        <div className='rangesSection'>
                            <RangesModalDisplayer show={props.showModalState[2]} handleClose={props.onHideModal[2]}
                                                  range={props.ranges[2]} quizzTitle={props.quizzTitles[2]}
                                                  rangesVSLabelPosition={props.rangesVSLabelPosition[2]}>
                                <p>Modal</p>
                            </RangesModalDisplayer>
                            <button className='myRangeDisplayButton' onClick={props.onShowModal[2]}
                                    disabled={!props.canSelectQuizz}><FontAwesomeIcon
                                icon={faEye} color="#000000" size="sm"/></button>
                            <button className={props.rangesStyle[2]} onClick={props.onClickRangesChoice[2]}
                                    disabled={!props.canSelectQuizz}>
                                <b>{props.quizzTitles[2]}</b> {' (' + props.nbQuestionsPerQuizzItem[2] + ' questions)'}
                            </button>
                        </div>
                    </div>
                    <div className='MyQuestionSelectorSection'>
                        <b>Equity</b>
                        <div className='rangesSection'>
                            <button className={props.rangesStyle[3]} onClick={props.onClickRangesChoice[3]}
                                    disabled={!props.canSelectQuizz}>
                                <b>{props.quizzTitles[3]}</b> {' (' + props.nbQuestionsPerQuizzItem[3] + ' questions)'}
                            </button>
                        </div>
                        <div className='rangesSection'>
                            <button className={props.rangesStyle[4]} onClick={props.onClickRangesChoice[4]}
                                    disabled={!props.canSelectQuizz}>
                                <b>{props.quizzTitles[4]}</b> {' (' + props.nbQuestionsPerQuizzItem[4] + ' questions)'}
                            </button>
                        </div>
                    </div>
                    <div key={`inline-checkbox`} className="mb-3 MyPositionFilters">
                        <div>
                            <Form.Check inline label="UTG" name="group1" type='checkbox' id='0'
                                        disabled={!props.canSelectQuizz} checked={props.positionFilters[0]}
                                        onClick={props.onClickPositionFilter[0]} readOnly={props.positionFilters[0]}/>
                            <Form.Check inline label="HJ" name="group1" type='checkbox' id='1'
                                        disabled={!props.canSelectQuizz} checked={props.positionFilters[1]}
                                        onClick={props.onClickPositionFilter[1]} readOnly={props.positionFilters[1]}/>
                            <Form.Check inline label="CO" name="group1" type='checkbox' id='2'
                                        disabled={!props.canSelectQuizz} checked={props.positionFilters[2]}
                                        onClick={props.onClickPositionFilter[2]} readOnly={props.positionFilters[2]}/>
                        </div>
                        <div>
                            <Form.Check inline label="BUT" name="group1" type='checkbox' id='3'
                                        disabled={!props.canSelectQuizz} checked={props.positionFilters[3]}
                                        onClick={props.onClickPositionFilter[3]} readOnly={props.positionFilters[3]}/>
                            <Form.Check inline label="SB" name="group1" type='checkbox' id='4'
                                        disabled={!props.canSelectQuizz} checked={props.positionFilters[4]}
                                        onClick={props.onClickPositionFilter[4]} readOnly={props.positionFilters[4]}/>
                            <Form.Check inline label="BB" name="group1" type='checkbox' id='5'
                                        disabled={!props.canSelectQuizz} checked={props.positionFilters[5]}
                                        onClick={props.onClickPositionFilter[5]} readOnly={props.positionFilters[5]}/>
                        </div>
                    </div>
                </fieldset>
                <div className='buttonStartRanges'>
                    <Button variant="default" className='myButtonStyle' disabled={!props.startStopButtonEnable}
                            onClick={props.onClickStartStopQuizz}>
                        <div
                            style={myButtonTextStyle}>{props.displayTextOnButton}</div>
                    </Button>
                </div>
            </div>
        )
    }
}

// https://www.digitalocean.com/community/tutorials/react-modal-component