import React from 'react'
import './css/QuizzDisplayer.css'
import {QuestionDisplayer} from "../common/QuestionDisplayer"
import {FlopHeaderDisplayer} from "./FlopHeaderDisplayer";
import {QuestionDescriptionDisplayer} from "../common/QuestionDescriptionDisplayer";
import {NavigationInQuizzPage} from "../navigation/NavigationInQuizzPage"
import {QuizzManager} from "../common/QuizzManager";

export class FlopDisplayer extends React.Component<any, any> {
    _quizzManager: QuizzManager

    constructor(props: any) {
        super(props)
        this._quizzManager = new QuizzManager(this.props.userProfile)
        this.state = {displayHelpers: true}
    }

    componentDidMount() {
        this._quizzManager.registerItemQuizzSelectorChecked(() => {
            this.registerItemQuizzSelectorCheckedNotification()
        })
    }

    registerItemQuizzSelectorCheckedNotification() {
        const displayErgoHelpers = this._quizzManager._maxQuestionNumberForOngoingQuizz === 0
        this.setState({displayHelpers: displayErgoHelpers})
    }

    render() {
        return (
            <div className='quizz'>
                <div>
                    <FlopHeaderDisplayer quizzManager={this._quizzManager}/>
                </div>
                {!this.state.displayHelpers ?
                        <div>
                            <QuestionDescriptionDisplayer quizzManager={this._quizzManager}/>
                            <QuestionDisplayer quizzManager={this._quizzManager}/>
                        </div>
                    : <NavigationInQuizzPage />
                }
            </div>
        )
    }
}