import React from 'react'
import './css/PlayerInfoDisplayer.css'

export class PlayerInfoDisplayer extends React.Component<any, any> {

    componentDidMount() {
        this.props.quizzManager.registerSubscriberQuestionChange((question: any) => {
            this.receiveChangeQuestionNotification(question)
        })
    }

    receiveChangeQuestionNotification(question: any) {
        this.setState({question: question})
    }

    render() {
        if (!this.state)
            return null

        const question = this.state.question
        let stringNameDisplay = ''
        let stringToDisplay = ''

        if (!question)
            return null

        //console.log(`Info Player: ${JSON.stringify(this.state.question)}`)
        if (this.props.playerId === 1) {
            if (!question.player1Name)
                return null

            stringNameDisplay += question.player1Name
            if (question.player1Type)
                stringToDisplay += ` (${question.player1Type} - ${question.Player1RangePercentage}) - ${question.player1Position} - ${question.player1Action}`
            else if (question.Player1RangePercentage)
                stringToDisplay += `'s hand range (${question.Player1RangePercentage})`
            else
                stringToDisplay += `'s hand`
        } else {
            if (!question.player2Name)
                return null

            stringNameDisplay += question.player2Name
            if (question.player2Type)
                stringToDisplay += `'s hand (${question.player2Type} - ${question.Player2RangePercentage}) - ${question.player2Position} - ${question.player2Action}`
            else if (question.Player2RangePercentage)
                stringToDisplay += `'s hand range (${question.Player2RangePercentage})`
            else
                stringToDisplay += `'s hand`
        }


        return (
            <div>
                <div className="playerInfoDisplayerArea">
                    <b>{stringNameDisplay}</b>
                    {stringToDisplay}
                </div>
            </div>
        )
    }
}