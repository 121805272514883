import {LoadCardsAndThumbsFromFile} from "./LoadCardsAndThumbsFromFile"
import {QuizzQuestionsDataStore} from "./QuizzQuestionsDataStore";
import {UserProfile} from "../userprofile/UserProfile";

export class QuizzManager {
    private _currentQuestionsList: Array<any> = []
    private _currentQuestionsDoneList: Array<boolean> = []

    private _currentQuestionId = -1

    public _currentQuestionNumberForOngoingQuizz = 0
    public _maxQuestionNumberForOngoingQuizz = 0

    public _responseId = 0
    public _score = 0
    public _maxScore = 0

    protected listQuizzStoppedSubscriber: Array<() => void> = []
    protected listQuestionChangeSubscriber: Array<(question: any) => void> = []
    protected listQuestionDoneSubscriber: Array<() => void> = []
    protected listQuestionAvailableForCheckSubscriber: Array<() => void> = []
    protected listItemQuizzSelectorChecked: Array<() => void> = []

    _loadCardsAndThumbsFromFile = new LoadCardsAndThumbsFromFile()

    _questionsDataStore = new QuizzQuestionsDataStore()
    _userProfile : UserProfile

    constructor(userProfile: UserProfile) {
        this._userProfile = userProfile
    }

    resetQuizzState() {
        this._currentQuestionsList = []
        this._currentQuestionsDoneList = []

        this._currentQuestionId = -1

        this._currentQuestionNumberForOngoingQuizz = 0
        this._maxQuestionNumberForOngoingQuizz = 0

        this._responseId = 0
    }

    startQuizz() {
        this.changeQuestion()
    }

    registerQuizzStoppedChange(callback: () => void) {
        this.listQuizzStoppedSubscriber.push(callback)
    }

    stopQuizz() {
        this.listQuizzStoppedSubscriber.forEach((subscriber) => {
            subscriber()
        })

        this._questionsDataStore.deselectAllQuizz()
        this.resetQuizzState()
    }

    registerSubscriberQuestionChange(callback: (question: any) => void) {
        this.listQuestionChangeSubscriber.push(callback)
        if (this._currentQuestionsList)
            callback(this._currentQuestionsList[this._currentQuestionId])
    }

    changeQuestion() {
        this._currentQuestionNumberForOngoingQuizz++
        if (this._currentQuestionId !== -1)
            this._currentQuestionsDoneList[this._currentQuestionId] = true

        this._currentQuestionId = this.getNextQuestionId()

        //console.log(`Change question: ${JSON.stringify(this._currentQuestionsList[this._currentQuestionId])}`)

        this.listQuestionChangeSubscriber.forEach((subscriber) => {
            subscriber(this._currentQuestionsList[this._currentQuestionId])
        })
    }

    private getNextQuestionId() {
        // Pick a question in the available list
        let nextQuestionId = 0
        const nbAvailableQuestions = this.getNbAvailableQuestions()
        let nextQuestionRelativeIndex = Math.floor(Math.random() * nbAvailableQuestions)
        //console.log(`nbAvailableQuestions: ${nbAvailableQuestions}  nextQuestionRelativeIndex:${nextQuestionRelativeIndex} `)

        // 3 questions available: true false true -false- false
        // Pick 2nd one
        for (let i = 0; i < this._currentQuestionsDoneList.length; i++) {
            if (!this._currentQuestionsDoneList[i])
                if (nextQuestionRelativeIndex === 0) {
                    nextQuestionId = i
                    break
                } else
                    nextQuestionRelativeIndex--
        }

        //console.log(`GetNextQuestionId: ${nextQuestionId} ${this._currentQuestionsDoneList}`)

        return nextQuestionId
    }

    getNbAvailableQuestions() {
        let nbAvailableQuestions = 0
        for (let i = 0; i < this._currentQuestionsDoneList.length; i++)
            if (!this._currentQuestionsDoneList[i])
                nbAvailableQuestions++

        return nbAvailableQuestions
    }

    registerSubscriberQuestionDone(callback: () => void) {
        this.listQuestionDoneSubscriber.push(callback)
        return this.listQuestionDoneSubscriber.length-1
    }

    questionDone() {
        const currentQuestion = this._currentQuestionsList[this._currentQuestionId]
        const pointsToAdd = parseInt(currentQuestion[`pointAnswer${this._responseId}`])
        this._score += pointsToAdd

        let maxScore = Math.max(parseInt(currentQuestion.pointAnswer1), parseInt(currentQuestion.pointAnswer2))
        if(currentQuestion.pointAnswer3)
            maxScore = Math.max(maxScore, parseInt(currentQuestion.pointAnswer3))
        if(currentQuestion.pointAnswer4)
            maxScore = Math.max(maxScore, parseInt(currentQuestion.pointAnswer4))
        if(currentQuestion.pointAnswer5)
            maxScore = Math.max(maxScore, parseInt(currentQuestion.pointAnswer5))
        this._maxScore +=maxScore

        this._userProfile.addPointForAQuestion(pointsToAdd)

        this.listQuestionDoneSubscriber.forEach((subscriber) => {
            subscriber()
        })
    }

    registerSubscriberQuestionAvailableForCheck(callback: () => void) {
        this.listQuestionAvailableForCheckSubscriber.push(callback)
        return this.listQuestionAvailableForCheckSubscriber.length-1
    }

    questionAvailableForCheck(responseId: number) {
        //console.log(`questionAvailableForCheck ${responseId}`)
        this._responseId = responseId
        this.listQuestionAvailableForCheckSubscriber.forEach((subscriber) => {
            subscriber()
        })
    }

    checkOnQuizzItemSelector(index: number, quizzItemInfosIds: number[]) {
        //console.log(`QuizzManager onClickQuizzChoice index: ${index}`)
        this._currentQuestionsList = []
        this._currentQuestionsDoneList = []
        this._questionsDataStore.checkOnQuizzItemSelector(index, quizzItemInfosIds, this._currentQuestionsList, this._currentQuestionsDoneList)
        this._maxQuestionNumberForOngoingQuizz = this._currentQuestionsDoneList.length
        //console.log(`Nb questions will be asked: ${this._currentQuestionsList.length}`)
        //console.log(`Questions will be asked: ${JSON.stringify(this._currentQuestionsList[0][0])}`)

        this.listItemQuizzSelectorChecked.forEach((subscriber) => {
            subscriber()
        })
    }

    registerItemQuizzSelectorChecked(callback: () => void) {
        this.listItemQuizzSelectorChecked.push(callback)
    }

    checkOnClickPositionFilter(position: number, quizzItemInfosIds: number[]){
        this._currentQuestionsList = []
        this._currentQuestionsDoneList = []
        this._questionsDataStore.checkOnClickPositionFilter(position, quizzItemInfosIds, this._currentQuestionsList, this._currentQuestionsDoneList)
        this._maxQuestionNumberForOngoingQuizz = this._currentQuestionsDoneList.length
        //console.log(`QuizzManager- Nb questions will be asked: ${this._currentQuestionsList.length}`)
        //console.log(`Questions will be asked: ${JSON.stringify(this._currentQuestionsList[0][0])}`)

        this.listItemQuizzSelectorChecked.forEach((subscriber) => {
            subscriber()
        })
    }
}
