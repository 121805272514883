import React, {CSSProperties} from 'react'
import {Navbar, Nav, Container} from 'react-bootstrap'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlay, faChartLine, faEnvelope, faBuilding} from '@fortawesome/free-solid-svg-icons'
import Logo from '../resources/LogoPokerReloadedForPCR.png'
import './Navigation.css'

export class Navigation extends React.Component<any, any> {
    render() {
        const navStyle: CSSProperties = {
            padding: '12px 0px 0px 60px',
            display: 'flex'
        }

        const itemMenu: CSSProperties = {
            padding: '10px 40px 0 0',
            fontFamily: 'Quicksand',
            fontSize: 'x-large',
            display: 'flex'
        }
        const textStyle: CSSProperties = {
            padding: '0px 0px 0px 10px'
        }

        const MyNavBarStyle: CSSProperties = {
            borderBottom: '1px solid lightgrey'
        }

        return (
            <Navbar collapseOnSelect bg='white' fixed='top' expand='sm' style={MyNavBarStyle}>
                <Container>
                    <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav>
                            <Nav.Link as={Link} to='/'>
                                <img src={Logo} width="80" height="auto" alt=""/>
                            </Nav.Link>
                            <div style={navStyle}>
                                <Nav.Link as={Link} to='/preflopquiz' style={itemMenu}>
                                    <FontAwesomeIcon icon={faBuilding} color="#000000" size="lg"/>
                                    <div style={textStyle}>Preflop quiz</div>
                                </Nav.Link>
                                <Nav.Link as={Link} to='/flopquiz' style={itemMenu}>
                                    <FontAwesomeIcon icon={faPlay} color="#000000" size="lg"/>
                                    <div style={textStyle}>Flop quiz</div></Nav.Link>
                                <Nav.Link as={Link} to='/userprofile' style={itemMenu}>
                                    <FontAwesomeIcon icon={faChartLine} color="#000000" size="lg"/>
                                    <div style={textStyle}>Your progression</div>
                                </Nav.Link>
                                <Nav.Link as={Link} to='/suggestions' style={itemMenu}>
                                    <FontAwesomeIcon icon={faEnvelope} color="#000000" size="lg"/>
                                    <div style={textStyle}>Make a suggestion</div>
                                </Nav.Link>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}