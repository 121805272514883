export class rawChartData {
    id: string = ''
    data: Array<object> = []
}

export class oneAnswer {
    x: string = ''
    y: string = ''
}

export class UserProfile {
    _avatarImage = 'Hero Avatar'
    _pseudo = 'Hero'
    _PCRLevelLabel = ''
    _nbQuestionsAnswered = 0
    _PCRLevel = 0
    _dataForCharts: Array<any> = []
    private _pointsPerQuestions: Array<number> = []

    addPointForAQuestion(points: number) {
        this._nbQuestionsAnswered++
        this._pointsPerQuestions.push(points)
        this._PCRLevel = this.computePCRLevel()
    }

    // Max points per question 3 (1 and 0 are others possibilities)
    computePCRLevel() {
        let PKRLevel: number

        let totalPoints = 0
        let currentIndex = 0
        let currentDataForCharts: Array<number> = []
        this._pointsPerQuestions.forEach((points) => {
            totalPoints += points
            currentIndex++

            currentDataForCharts.push(totalPoints / currentIndex * 1000)
        })
        const averagePoints = totalPoints / this._pointsPerQuestions.length

        PKRLevel = Math.round(averagePoints * 1000)

        this._PCRLevelLabel = 'Novice'
        if (PKRLevel > 1000)
            this._PCRLevelLabel = 'Intermediate'
        if (PKRLevel > 1500)
            this._PCRLevelLabel = 'Advanced'
        if (PKRLevel > 2000)
            this._PCRLevelLabel = 'Master'
        if (PKRLevel > 2500)
            this._PCRLevelLabel = 'GrandMaster'

        let rawChartData: rawChartData = {
            id: '',
            data: []
        }

        rawChartData['id'] = this._pseudo
        for (let i = 1; i <= currentDataForCharts.length; i++) {
            let oneAnswer: oneAnswer = {
                x: '',
                y: ''
            }
            oneAnswer['x'] = i.toString()
            oneAnswer['y'] = currentDataForCharts[i-1].toString()
            rawChartData['data'].push(oneAnswer)
        }

        this._dataForCharts = []
        this._dataForCharts.push(rawChartData)
        //console.log(`rawData: ${JSON.stringify(this._dataForCharts)}`)

        return PKRLevel
    }
}