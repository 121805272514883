import {QuizzItemInfos, RegisterAllQuizzItemInfos} from "./RegisterAllQuizzItemInfos";
import raw from "raw.macro";
import {parse, ParseResult} from "papaparse";
import {RangesQuestionEnricher} from "./RangesQuestionEnricher";
import {VariousQuestionEnricher} from "./VariousQuestionEnricher";


export class QuizzQuestionsDataStore {
    private _rangeQuestionEnricher = new RangesQuestionEnricher()
    private _variousQuestionEnricher = new VariousQuestionEnricher()

    _quizzItemInfos: Array<QuizzItemInfos> = []
    public _positionFilter = [true, true, true, true, true, true]
    public _positionFilterNames = ['UTG', 'HJ', 'CO', 'BT', 'SB', 'BB']

    constructor() {
        this._quizzItemInfos = RegisterAllQuizzItemInfos.getQuizzItemInfos()
        this.grabQuizzQuestionsFromDisk()
    }

    private grabQuizzQuestionsFromDisk() {
        this._quizzItemInfos.forEach((quizzItem) => {
            const csvFileName = quizzItem.serverURL
            const questionsFileinCSV = raw(`../resources/questions/${csvFileName}.csv`)
            const questionsInJSON: ParseResult<unknown> = parse(questionsFileinCSV, {delimiter: ";", header: true})

            if (quizzItem.type === 'range') {
                this.buildMatrixModals(quizzItem, questionsInJSON)
                quizzItem.questions = this._rangeQuestionEnricher.enrichQuestions(questionsInJSON.data)
                quizzItem.nbQuestionsAvailable = this.computeNbRangesQuestionsAvailable(quizzItem)
            } else {
                quizzItem.questions = this._variousQuestionEnricher.enrichQuestions(questionsInJSON.data, quizzItem.title)

                // Parsing bug of csv files (one empty question at the end) -> let's remove it
                quizzItem.questions.pop()
                quizzItem.nbQuestionsAvailable = this.computeNbVariousQuestionsAvailable(quizzItem)
            }

            quizzItem.nbQuestionsPreselected = quizzItem.nbQuestionsAvailable
        })
    }

    buildMatrixModals(rangesItem: QuizzItemInfos, questionsInJSON: ParseResult<unknown>) {
        questionsInJSON.data.forEach((lineInCSV: any) => {
            if (lineInCSV.player2Position === 'UTG') {
                rangesItem.rangeUTG.push(this._rangeQuestionEnricher.convertTextRangeToBooleanArray(lineInCSV.player2Range))
                rangesItem.rangeUTGvsPositionLabel.push(lineInCSV.player1Position)
            }
            if (lineInCSV.player2Position === 'HJ') {
                rangesItem.rangeHJ.push(this._rangeQuestionEnricher.convertTextRangeToBooleanArray(lineInCSV.player2Range))
                rangesItem.rangeHJvsPositionLabel.push(lineInCSV.player1Position)
            }
            if (lineInCSV.player2Position === 'CO') {
                rangesItem.rangeCO.push(this._rangeQuestionEnricher.convertTextRangeToBooleanArray(lineInCSV.player2Range))
                rangesItem.rangeCOvsPositionLabel.push(lineInCSV.player1Position)
            }
            if (lineInCSV.player2Position === 'BT') {
                rangesItem.rangeBT.push(this._rangeQuestionEnricher.convertTextRangeToBooleanArray(lineInCSV.player2Range))
                rangesItem.rangeBTvsPositionLabel.push(lineInCSV.player1Position)
            }
            if (lineInCSV.player2Position === 'SB') {
                rangesItem.rangeSB.push(this._rangeQuestionEnricher.convertTextRangeToBooleanArray(lineInCSV.player2Range))
                rangesItem.rangeSBvsPositionLabel.push(lineInCSV.player1Position)
            }
            if (lineInCSV.player2Position === 'BB') {
                rangesItem.rangeBB.push(this._rangeQuestionEnricher.convertTextRangeToBooleanArray(lineInCSV.player2Range))
                rangesItem.rangeBBvsPositionLabel.push(lineInCSV.player1Position)
            }
        })
    }

    private computeNbRangesQuestionsAvailable(rangeSelectorItem: QuizzItemInfos) {
        let NbQuestionsAvailable = 0
        rangeSelectorItem.questions.forEach((range: any) => {
            range.forEach(() => {
                NbQuestionsAvailable++
            })
        })

        return NbQuestionsAvailable
    }

    private computeNbVariousQuestionsAvailable(rangeSelectorItem: QuizzItemInfos) {
        return rangeSelectorItem.questions.length
    }

    deselectAllQuizz() {
        this._quizzItemInfos.forEach((quizzItem) => {
            quizzItem.selected = false
        })
    }

    checkOnQuizzItemSelector(index: number, quizzItemInfosIds: number[], currentQuestionsList: any[], currentQuestionsDoneList: any[]) {
        this._quizzItemInfos[index].selected = !this._quizzItemInfos[index].selected

        this.computeQuestionsSelectedQuestions(quizzItemInfosIds, currentQuestionsList, currentQuestionsDoneList)
    }

    checkOnClickPositionFilter(positionFilter: number, quizzItemInfosIds: number[], currentQuestionsList: any[], currentQuestionsDoneList: any[]) {
        this._positionFilter[positionFilter] = !this._positionFilter[positionFilter]

        this.computeQuestionsSelectedQuestions(quizzItemInfosIds, currentQuestionsList, currentQuestionsDoneList)
    }

    computeQuestionsSelectedQuestions(quizzItemInfosIds: number[], currentQuestionsList: any[], currentQuestionsDoneList: any[]) {
        this._quizzItemInfos.forEach((quizzItemInfos) => {
            if (quizzItemInfosIds.indexOf(quizzItemInfos.id) !== -1) {
                quizzItemInfos.nbQuestionsPreselected = 0
                quizzItemInfos.questions.forEach((questionsList: any) => {
                    if (questionsList.length) {   // Multiple questions in one CSV line (ex: Open Raise)
                        questionsList.forEach((question: any) => {
                            //console.log(`computeRangesSelectedQuestions1- question:${JSON.stringify(JSON.stringify(question))}`)
                            if (this._positionFilter[this._positionFilterNames.indexOf(question.position)]) {
                                quizzItemInfos.nbQuestionsPreselected++
                                if (quizzItemInfos.selected) {
                                    currentQuestionsList.push(question)
                                    currentQuestionsDoneList.push(false)
                                }
                            }
                        })
                    }
                    else{
                        const question = questionsList
                        //console.log(`computeRangesSelectedQuestions2- question:${JSON.stringify(JSON.stringify(question))}`)
                            quizzItemInfos.nbQuestionsPreselected++
                            if (quizzItemInfos.selected) {
                                currentQuestionsList.push(question)
                                currentQuestionsDoneList.push(false)
                            }
                    }
                })
            }
        })

        console.log(`computeRangesSelectedQuestions2- currentQuestionsList.length: ${currentQuestionsList.length}`)
    }
}