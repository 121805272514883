import React from 'react'
import './css/ScoreDisplayer.css'
import {QuizzManager} from "./QuizzManager";

export class ScoreDisplayer extends React.Component<any, any> {
    private _quizzManager!: QuizzManager

    constructor(props: any) {
        super(props)
        this._quizzManager = this.props.quizzManager
        this.state = {score: 0, maxScore: 0}
    }

    componentDidMount() {
        if(this._quizzManager) {
            this._quizzManager.registerQuizzStoppedChange(() => {
                this.receiveQuizzStoppedNotification()
            })

            this._quizzManager.registerSubscriberQuestionDone(() => {
                this.receiveChangeQuestionDone()
            })
        }
    }

    receiveChangeQuestionDone() {
        this.setState({score: this.props.quizzManager._score, maxScore: this.props.quizzManager._maxScore})
        //console.log(`Score ${this.state.score} ${this.state.maxScore}`)
    }

    private receiveQuizzStoppedNotification() {
        this.setState({score: this.props.quizzManager._score, maxScore: this.props.quizzManager._maxScore})
    }

    render() {
        return (
            <div>
                <fieldset className="myScoreFieldset">
                    <legend className="w-auto myScoreLegend">Score</legend>
                    <div className='myScoreText'>{this.state.score}/{this.state.maxScore}</div>
                </fieldset>
            </div>
        )
    }
}
