import React, {CSSProperties} from 'react'
import {StaticSquareDisplayer} from "./StaticSquareDisplayer";

export class StaticRangesMatrixDisplayer extends React.Component<any, any> {

    renderMatrix(): JSX.Element {
        let matrix = []

        for (let raw = 0; raw < 13; raw++)
            // @ts-ignore
            matrix.push(this.renderMatrixRaw(raw, this.props.range))

        return (
            <div key={`${this.props.playerId}`}>
                {matrix}
            </div>
        )
    }

    renderMatrixRaw(raw: number, range: [][]) {
        let squareArray = []
        for (let index = 0; index < 13; index++) {
            squareArray.push(this.renderMatrixSquare(raw, index, range))
        }

        const OneMatrixLine: CSSProperties = {
            display: 'flex',
            flexDirection: 'row'
        }

        return (
            <div key={`1_${raw}`} style={OneMatrixLine}>
                {squareArray}
            </div>
        )
    }

    renderMatrixSquare(raw: number, index: number, range: [][]): JSX.Element {
        return (
            <StaticSquareDisplayer
                key={`1_${raw}_${index}`}
                range={range}
                row={raw}
                index={index}
            />
        )
    }

    render() {
        if (!this.props.range)
            return null

        //console.log(`RenderRangesMatrix: ${JSON.stringify(this.props.range)}`)

        return (this.renderMatrix())
    }
}