import React from 'react'
import {CaptureAnswerDisplayer} from "./CaptureAnswerDisplayer";
import './css/QuestionAnswerDisplayer.css';
import {EquityAnswerDisplayer} from "./EquityAnswerDisplayer";
import {ValidateQuestionButtonDisplayer} from "./ValidateQuestionButtonDisplayer";
import {NextQuestionButtonDisplayer} from "./NextQuestionButtonDisplayer";
import {NavigationToProgress} from "../navigation/NavigationToProgress";

export class QuestionAnswerDisplayer extends React.Component<any, any> {

    constructor(props: any) {
        super(props)
        this.state = {validateButtonShow: true, nextButtonShow: false}
    }

    componentDidMount() {
        this.props.quizzManager.registerSubscriberQuestionChange((question: any) => {
            this.receiveChangeQuestionNotification(question)
        })

        this.props.quizzManager.registerSubscriberQuestionDone(() => {
            this.receiveChangeQuestionDone()
        })
    }

    receiveChangeQuestionNotification(question: any) {
        this.setState({question: question, validateButtonShow: true, nextButtonShow: false})
    }

    private receiveChangeQuestionDone() {
        this.setState({validateButtonShow: false})
        if(this.props.quizzManager.getNbAvailableQuestions() < 2)
            this.setState({nextButtonShow: false})
        else
            this.setState({nextButtonShow: true})
    }

    render() {
        if (!this.state)
            return null
        if (!this.state.question)
            return null

        return (
            <this.display
                quizzManager={this.props.quizzManager}
                validateButtonShow={this.state.validateButtonShow}
                nextButtonShow={this.state.nextButtonShow}
            />
        )
    }

    display(props: any) {
        return (
            <div>
                <fieldset className="myQuestionAnswerFieldset">
                    <legend className="w-auto myQuestionAnswerLegend">Your answer</legend>
                    <div className='myCaptureAnswerDisplayer'>
                        <CaptureAnswerDisplayer quizzManager={props.quizzManager}/>
                    </div>
                </fieldset>
                <div className='answerButton'>
                    {props.validateButtonShow && <ValidateQuestionButtonDisplayer quizzManager={props.quizzManager}/>}
                    {props.nextButtonShow && <NextQuestionButtonDisplayer quizzManager={props.quizzManager}/>}
                </div>
                <div>
                    {props.nextButtonShow && <EquityAnswerDisplayer quizzManager={props.quizzManager}/>}
                    {!props.nextButtonShow && !props.validateButtonShow && <NavigationToProgress />}
                </div>
            </div>
        )
    }

}