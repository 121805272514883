import React from "react";
import {PreflopSelectorDisplayer} from "./PreflopSelectorDisplayer";
import './css/PreflopHeaderDisplayer.css'
import {ScoreDisplayer} from "../common/ScoreDisplayer";

export class PreflopHeaderDisplayer extends React.Component<any, any> {
    render() {
        return (
            <div className={'rangesHeader'}>
                <PreflopSelectorDisplayer quizzManager={this.props.quizzManager}/>
                <ScoreDisplayer quizzManager={this.props.quizzManager}/>
            </div>
        )
    }
}