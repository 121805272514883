import React, {CSSProperties} from 'react'
import './css/QuestionDescriptionDisplayer.css'

export class QuestionDescriptionDisplayer extends React.Component<any, any> {
    componentDidMount() {
        this.props.quizzManager.registerQuizzStoppedChange(() => {
            this.receiveQuizzStoppedNotification()
        })

        this.props.quizzManager.registerSubscriberQuestionChange((question: any) => {
            this.receiveChangeQuestionNotification(question)
        })
    }

    private receiveQuizzStoppedNotification() {
        this.setState({question: null})
    }

    receiveChangeQuestionNotification(question: any) {
        this.setState({question: question})
    }

    render() {
        if (!this.state)
            return null

        const question = this.state.question
        if (!question)
            return null

        const thumbStyle: CSSProperties = {
            padding: '0px',
            borderRadius: '1.5rem'
        }

        const descriptionHeader = `Question (${this.props.quizzManager._currentQuestionNumberForOngoingQuizz}/${this.props.quizzManager._maxQuestionNumberForOngoingQuizz}): ${question.questionTitle}`
        const descriptionText = this.extractDescriptionText(question.description)
        const questionText = this.extractQuestionText(question.description)
        let thumbImageName = this.state.question.question_id + '_thumb.jpg'
        if(this.state.question.thumb_id)
            thumbImageName = this.state.question.thumb_id + '_thumb.jpg'

        //console.log(`thumbImageName: ${thumbImageName}`)

        const thumbExists: boolean = this.props.quizzManager._loadCardsAndThumbsFromFile._thumbImages[thumbImageName]

        return (
            <div className="questionDescription">
                {thumbExists &&
                <img src={this.props.quizzManager._loadCardsAndThumbsFromFile._thumbImages[thumbImageName].default}
                     alt={thumbImageName} style={thumbStyle} width={'480px'}
                     height={'auto'}/>}
                <div className="questionTextDescription">
                    <div className='descriptionHeader'>{descriptionHeader}</div>
                    <div className={'MyQuestionSection'}>
                        <div className='descriptionText'>{descriptionText}</div>
                        <div className='questionText'><b>{questionText}</b></div>
                    </div>
                </div>
            </div>
        )
    }

    private extractDescriptionText(description: string) {
        if (!description)
            return description

        let descriptionWithNewLine = description
        if (description.lastIndexOf('_') !== -1)
            descriptionWithNewLine = description.substr(0, description.lastIndexOf('_'))

        descriptionWithNewLine = descriptionWithNewLine.split('.').join(`.\n`)

        return descriptionWithNewLine
    }

    private extractQuestionText(description: string) {
        if (!description)
            return description

        if (description.lastIndexOf('_') === -1)
            return ''

        return description.split('_')[1]
    }
}