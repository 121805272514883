import React from 'react'
import './css/HoleCardsDisplayer.css'

export class HoleCardsDisplayer extends React.Component<any, any> {
    render(){
        if(!this.props.card)
            return null

        const cardToDisplay1 = this.props.card[0] + this.props.card[1] + '.png'
        const cardToDisplay2 = this.props.card[2] + this.props.card[3] + '.png'

        //console.log(`Hole cards to display: ${cardToDisplay1} ${cardToDisplay2}`)

        return (
            <div className='holeCardsDisplayer'>
                <img src={this.props.loadCardsAndThumbsFromFile._cardImages[cardToDisplay1].default} alt={cardToDisplay1} width={'100px'}
                     height={'auto'}/>
                <img src={this.props.loadCardsAndThumbsFromFile._cardImages[cardToDisplay2].default} alt={cardToDisplay2} width={'100px'}
                     height={'auto'}/>
            </div>
        )
    }
}