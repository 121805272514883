import React, {CSSProperties} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import './NavigationInQuizzPage.css'

export class NavigationInQuizzPage extends React.Component<any, any> {
    render(){
        const textStyle: CSSProperties = {
            fontSize: 'x-large',
            padding: '5px 0 0 20px'
        }

        const iconStyle: CSSProperties = {
            margin: '0 0 0 0'
        }

        return(
            <div className='navigationInQuizzPage'>
                <div className='iconStyle'>
                    <FontAwesomeIcon icon={faArrowLeft} color="tomato" size="3x" style={iconStyle}/>
                </div>
                <div style={textStyle}>Select one or more quizzes</div>
            </div>
        )
    }
}